import React from "react";

const EroScripts = ({ className }: { className?: string }): JSX.Element => {
    return (
        <svg
            className={className}
            viewBox="0 0 433 371"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            width="1em"
        >
            <circle cx="226.5" cy="41.5" r="31.5" />
            <circle cx="401.5" cy="51.5" r="31.5" />
            <path d="M284.5 358C284.5 358 290 347.5 288.5 333.5C340.5 341.5 366 322 366 322C366 322 376 329.5 399.5 329.5C378.5 346 377 348 346 356.5C315 365 284.5 358 284.5 358Z" />
            <path d="M231.5 103C231.5 103 215 104 201 97.5C203 119.5 213.5 134 213.5 134C213.5 134 223 151.5 249.5 166.5C276 181.5 294.5 187 317.5 193.5C340.5 200 363.5 214.5 363.5 214.5C363.5 214.5 378.5 204.5 397.5 205C374 184.5 361 182 340.5 172.5C320 163 289.5 161 254.5 137.5C234.5 122 231.5 103 231.5 103Z" />
            <path d="M356 8.50003C356 8.50003 326 -6 276.5 4.50003C284 16 287 27.5 287 27.5C287 27.5 318 20 342.5 30.5C347 17 356 8.50003 356 8.50003Z" />
            <path d="M173 10.5001L0.5 10.5V349H165C163 338 163 334 165 324.5H28.5V182.5H115V158.5H28.5V35H165C165 21.5 173 10.5001 173 10.5001Z" />
            <circle cx="226.5" cy="339.5" r="31.5" />
            <circle cx="401.5" cy="267.5" r="31.5" />
        </svg>
    );
};

export default EroScripts;
